
import axios from 'axios';
export default {
    async createQist(params)  {
        return await axios.post(`qist/create` , params)
    },
    async createQistList(params)  {
        return await axios.post(`qist/create/list` , params)
    },
    async updateQistColumn(column , value , data)  {
        return await axios.put(`qist/update_column?column=${column}&value=${value}` , data)
    },
    async deleteQistList(list)  {
        return await axios.delete(`qist/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportQist(column , value)  {
        return await axios.get(`qist/report?${column}=${value}`)
    },
    async getAllQist()  {
        return await axios.get(`qist/all`)
    },
    async getOneQist(qist_id)  {
        return await axios.get(`qist/all/${qist_id}`)
    },
    async getQistByColumn(column , value)  {
        return await axios.get(`qist/filter?column=${column}&value=${value}`)
    },
    async deleteQist(qist_id)  {
        return await axios.delete(`qist/delete/${qist_id}`)
    },
    async deleteQistColumn(column, value)  {
        return await axios.delete(`qist/delete_column?column=${column}&value=${value}`)
    },
    async updateQist(qist_id , params)  {
        return await axios.put(`qist/update/${qist_id}` , params)
    },
}
        