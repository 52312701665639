
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            
            <form @submit.prevent="updateQist" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="qist.qist_date" type="date" :label="$store.getters.language.data.qist.qist_date" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="qist.paid_date" type="date" :label="$store.getters.language.data.qist.paid_date" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['paid','not_paid']" v-model="qist.qist_status" dense  filled outlined item-text="qist_status"
                                    item-value="qist_status" :return-object="false" :label="$store.getters.language.data.qist.qist_status">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="users" v-model="qist.user_id" dense  filled outlined item-text="user_name"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.qist.user_name">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="qist.qist_amount" type="text" :label="$store.getters.language.data.qist.qist_amount" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-textarea rows="1" auto-grow v-model="qist.qist_note" type="textarea" :label="$store.getters.language.data.qist.qist_note" dense
                            class="mx-1"  filled outlined  >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"  type="submit">{{$store.getters.language.data.qist.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/qist.request.js'
    export default {
        data() {
            return {
                qist: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            users(){
                return this.$store.getters.users_list
            },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneQist()
        },
        methods: {
            getOneQist() {
                this.loading = true
                requests.getOneQist(this.id).then(r =>{
                    if (r.status == 200) {
                        this.qist = r.data.row
                        this.qist.qist_date = new Date(r.data.row.qist_date).toISOString().split('T')[0];
                        this.qist.paid_date = new Date(r.data.row.paid_date).toISOString().split('T')[0];
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Qist',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Qist',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateQist() {
                this.loading = true
                requests.updateQist(this.id,this.qist).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'Qist Updated',
                            color: 'success'
                        }
                        this.$router.push('/qist/' + this.qist.invoice_id)
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    